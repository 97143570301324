<template>
    <div class="person-view">
        <Head>
            <template v-slot:header>
                <div class="about">
                    <span class="name">{{ person.name }}</span>
                    <span class="grad">{{ person.about }}</span>
                </div>            
            </template>
        </Head>
    <div class="content">
        <div class="section abstract" v-if="person?.abstract?.main">
            <div class="photo-wrapper">
                <img :src="photo || person.photo" />
            </div>
            <div class="abstract-content">
                <div v-html="person.abstract.main"></div>
                <div>Дата рождения: {{ person.birthday }}</div>
                <div>Место рождения: {{ person.origin }}</div>
            </div>            
        </div>
        <div class="section" v-if="person?.abstract?.science">
            <h2>Научная деятельность</h2>
            <div v-html="person.abstract.science"></div>
        </div>
        <div class="section" v-if="person?.abstract?.teach">
            <h2>Педагогическая деятельность</h2>
            <div v-html="person.abstract.teach"></div>
        </div>
        <div class="section" v-if="person?.abstract?.social">
            <h2>Общественная деятельность</h2>
            <div v-html="person.abstract.social"></div>
        </div>
        <div class="section" v-if="person?.abstract?.awards">
            <h2>Достижения и награды</h2>
            <div v-html="person.abstract.awards"></div>
        </div>
        <div class="section" v-if="person.pubs">
            <h2>Ключевые публикации</h2>
            <div class="pubs">
                <div v-for="(pub, i) in person.pubs" style="display: flex; flex-direction: row;">
                    <span style="display: inline-block; min-width: 40px">{{ i+1 }}.</span> {{ pub }}
                </div>
            </div>        
        </div>
    </div>
    </div>
    </template>
    
    <script>
    
    import Head from './Head.vue'
    
    export default {
        components: { Head },
        props: {
            items: {
                type: Array,
                default: [],
            },
            person: {
                type: Object
            },
            photo: {
                type: String
            }
        },
    }
    </script>
    
<style scoped>

p {
    margin-top: 5px;
    line-height: 2;
}

.content {
    min-height: 200px;
    margin-top: 50px;
}

.section {
    margin-top: 30px;
}

.photo-wrapper {
    width: 400px;
    height: 100%;
    margin-top: 20px;
    margin-right: 100px;    
}

.photo-wrapper > img {
    width: 100%;
    box-shadow: 10px 5px 5px rgba(0,0,0,0.5);
}

.abstract {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.abstract-content {
    display: flex;
    flex-direction: column;
    width: 65%;
}

.abstract-content > div {
    padding-top: 20px;
}

.abstract .photo-wrapper {
}

.about {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about > span {
    padding: 5px;
}

.about .name {
    font-size: larger;
    font-weight: bold;
    text-decoration: underline;
}

.about .grad {
    font-size: smaller;
    text-decoration: underline;
}

.section {
    width: 100%;
}

.table {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.table-row {
    display: flex;
    flex-direction: row;
}

.table-column {
    padding: 5px;
}

.table-column.time {
    min-width: 150px;
}

.table-column > span::after {
    content: " ";
}

.position {
    font-weight: bold;
}

.sci-area {
    margin-top: 10px;
    font-weight: bold;
}

.sci-area-themes {
    margin-left: 20px;
}

.sci-area-theme {
    padding: 5px;
}

.scimetr > div {
    padding-top: 10px;
}

.pubs > div {
    padding-top: 10px;
}

</style>

<style scoped>

h2 {
    margin-bottom: 30px;
}

.student {
    padding-top: 10px;
}

</style>