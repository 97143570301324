<template>
<div class="header">
    <div class="head_wrapper">
        <div class="head-main">
            <div class="logo">
                <img src="/bmstu/logo/nti-bmstu-label.svg" />
            </div>
            <div class="text">
                <slot name="header"></slot>            
            </div>
        </div>
        <div class="head-right">
            <slot name="right"></slot>
        </div>        
    </div>
</div>
</template>

<script> 
export default {
}
</script>

<style scoped>

.header {
    width: 100%;
    margin: 0 auto;
    height: 415px;
    margin-bottom: 30px;
    background-image: url("../public/5171523.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
}


.head-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
    font-size: larger;
    flex: 1;
}
.head_wrapper {
    display: flex;
    flex-direction: row;
    height: 350px;
    margin-top: 70px;
}

@media screen and (max-width: 480px) {
    .header {
        height: auto;
    }
    .header-panel {
        width: 400px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .logo {
        width: 80%;
    }

    .head_info {
        justify-content: space-between;
    }

    .head_wrapper .text {
        display: none;
    }
}

@media screen and (max-width: 360px) {
    .header {
        height: auto;
    }
    .header-panel {
        width: 320px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .logo {
        width: 80%;
    }
    .head_info {
        justify-content: left;
    }

    .head_wrapper .text {
        display: none;
    }
}

</style>