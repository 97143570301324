<template>
<div class="person-view">
    <Head>
        <template v-slot:header>
            <div class="about">
                <span class="name">{{ person.name }}</span>
                <span class="grad">{{ person.about }}</span>
            </div>            
        </template>
        <template v-slot:right>
            <div class="photo-wrapper">
                <img :src="photo || person.photo" />
            </div>
        </template>
    </Head>
<div class="content">
    <div class="section">
        <p>{{ person.abstract }}</p>
        <p>Дата рождения: {{ person.birthday }}</p>
        <p>Место рождения: {{ person.origin }}</p>
    </div>
    <div class="section" v-if="person.education">
        <h2>Образование</h2>
        <div class="table">
            <div class="table-row" v-for="item in person.education">
                <div class="table-column time">{{ item.time }}</div> 
                <div class="table-column">
                    <span class="position">{{ item.position }}</span>
                    <span class="org">{{ item.org }}</span>
                </div> 
            </div>
            <div class="table-row" v-if="person.dissertation">
                <div class="table-column time"></div> 
                <div class="table-column">
                    {{ person.dissertation }}
                </div> 
            </div>
        </div>
    </div>
    <div class="section" v-if="person.carier">
        <h2>Работа</h2>
        <div class="table">
            <div class="table-row" v-for="item in person.carier">
                <div class="table-column time">{{ item.time }}</div> 
                <div class="table-column">
                    <span class="position">{{ item.position }}</span>
                    <span class="org">{{ item.org }}</span>
                </div> 
            </div>
        </div>
    </div>
    <div class="section" v-if="person.science">
        <h2>Научные интересы (область экспертизы)</h2>
        <div style="margin-top: 10px;" v-for="sci in person.science">
            <div class="sci-area">{{ sci.title }}</div>
            <div class="sci-area-themes">
                <div class="sci-area-theme" v-for="t in sci.subs">{{ t }}</div>
            </div>
        </div>
    </div>
    <div class="section" v-if="person.awards">
        <h2>Награды и премии</h2>
        <div class="table">
            <div class="table-row" v-for="item in person.awards">
                <div class="table-column time">{{ item.time }}</div> 
                <div class="table-column">{{ item.name }}</div> 
            </div>
        </div>
    </div>
    <div class="section" v-if="person.teaching">
        <h2>Педагогическая деятельность</h2>
        <div class="table">
            <div class="table-row" v-for="item in person.teaching">
                <div class="table-column time">{{ item.time }}</div> 
                <div class="table-column">{{ item.position }}</div> 
            </div>
        </div>
    </div>
    <div class="section" v-if="person.students">
        <h2>Руководство студентами и аспирантами</h2>
        <div v-for="s in person.students">
            <div class="student">{{ s }}</div>
        </div>
    </div>
    <div class="section" v-if="person.grants">
        <h2>Руководство научными проектами и грантами</h2>
        <div class="table">
            <div class="table-row" v-for="item in person.grants">
                <div class="table-column time">{{ item.time }}</div> 
                <div class="table-column">
                    <span class="position">{{ item.founder }}</span>
                    <span class="org">{{ item.title }}</span>
                </div> 
            </div>
        </div>
    </div>
    <div class="section" v-if="person.social">
        <h2>Общественная деятельность</h2>
        <div class="table">
            <div class="table-row" v-for="item in person.social">
                <div class="table-column time">{{ item.time }}</div> 
                <div class="table-column">{{ item.position }}</div> 
            </div>
        </div>
    </div>
    <div class="section scimetr" v-if="person.scopus || person.hirsh">
        <h2>Публикационная активность (2013 - н.в.)</h2>
        <div v-if="person.scopus">Количество публикаций (Scopus): <span class="scopus">{{ person.scopus }}</span></div>
        <div v-if="person.hirsh">Индекс Хирша (Scopus): <span class="hirsh">{{ person.hirsh }}</span></div>
    </div>
    <div class="section" v-if="person.pubs">
        <h2>10 избранных публикаций (хронологический порядок)</h2>
        <div class="pubs">
            <div v-for="(pub, i) in person.pubs" style="display: flex; flex-direction: row;">
                <span style="display: inline-block; min-width: 40px">{{ i+1 }}.</span> {{ pub }}
            </div>
        </div>        
    </div>
</div>
</div>
</template>

<script>

import Head from './Head.vue'

export default {
    components: { Head },
    props: {
        items: {
            type: Array,
            default: [],
        },
        person: {
            type: Object
        },
        photo: {
            type: String
        }
    },
}
</script>

<style>

.content {
    min-height: 200px;
}

.section {
    margin-top: 20px;
}

.photo-wrapper {
    width: 400px;
    height: 100%;
    margin-top: 20px;
    margin-right: 100px;
    
}

.photo-wrapper > img {
    width: 100%;
    box-shadow: 10px 5px 5px rgba(0,0,0,0.5);
}

.about {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about > span {
    padding: 5px;
}

.about .name {
    font-size: larger;
    font-weight: bold;
    text-decoration: underline;
}

.about .grad {
    font-size: smaller;
    text-decoration: underline;
}

.section {
    width: 100%;
}

.table {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.table-row {
    display: flex;
    flex-direction: row;
}

.table-column {
    padding: 5px;
}

.table-column.time {
    min-width: 150px;
}

.table-column > span::after {
    content: " ";
}

.position {
    font-weight: bold;
}

.sci-area {
    margin-top: 10px;
    font-weight: bold;
}

.sci-area-themes {
    margin-left: 20px;
}

.sci-area-theme {
    padding: 5px;
}

.scimetr > div {
    padding-top: 10px;
}

.pubs > div {
    padding-top: 10px;
}

</style>

<style scoped>

h2 {
    margin-bottom: 30px;
}

.student {
    padding-top: 10px;
}

</style>