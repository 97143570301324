<template>
<Head>
    <template v-slot:header>
        <div class="job-header">
            <div style="font-size: x-large; font-weight: bold; padding-bottom: 20px">Вакансия</div>
            <div style=" font-weight: bold; padding-bottom: 10px">{{ job.title }}</div>
            <!--
            <div style="font-size: smaller">{{ job.salary }}</div>
            -->
        </div>
    </template>
</Head>
<div class="job content">
    <div class="job-main jsectiion">
        <div class="job-experience"><span class="label">Опыт работы:</span> {{ job.experience }}</div>
        <div class="job-schedule"><span class="label">{{ job.schedule }}</span></div>
    </div>
    <div class="job-skills jsectiion">
        <h3>Ключевые навыки</h3>
        <div v-for="skill in job.skills" class="item">
            {{ skill }}
        </div>
    </div>
    <div class="job-tasks jsectiion">
        <h3>Задачи</h3>
        <div v-for="task in job.tasks" class="item">
            {{ task }}
        </div>
    </div>
    <div class="job-requirements jsectiion">
        <h3>Требования</h3>
        <div v-for="req in job.requirements" class="item">
            {{ req }}
        </div>
    </div>
    <div class="job-offers jsectiion">
        <h3>Мы предлагаем Вам</h3>
        <div v-for="offer in offers" class="item">
            {{ offer }}
        </div>
    </div>
</div>

</template>

<script> 
import Head from './Head.vue'

export default {
    components: { Head },
    props: {
        job: {
            type: Object
        },
    },
    data() {
        return {
            offers: [
                'Работа в стабильной компании, занимающейся научными разработками в области полимерных материалов и композитов на их основе.',
                'Публикации в научных журналах, участие в конференциях, возможность защиты кандидатской диссертации по теме выполненных работ.',
                'Амбициозные и интересные задачи с достойным вознаграждением.',
                'Оформление по ТК.',
                'Бесплатное обучение на курсах Образовательного центра МГТУ им. Н.Э. Баумана.',
                'Удобный график работы: 5/2 с 9.00 до 18.00 либо 10.00 до 19.00',
                'Работа в пешей доступности от метро Бауманская',
                'Возможности для профессионального развития и карьерного роста.',
                'Заработная плата обсуждается по результатам собеседования.'
            ]
        }
    }
}
</script>

<style scoped>
h3 {
    margin-bottom: 10px;
}

.job {
    width: 100%;
}

.jsectiion {
    width: 100%;
}

.job-header {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: visible;
}

.label {
    font-weight: bold;
}

.item {
    padding-top: 5px;
}

.item::before {
    content: '• '
}

</style>