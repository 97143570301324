<template> 
<div class="projects-group">
    <div class="col-12 about_info_branch my-4">
        <div class="container">
            <div class="about_info_branch_title_left">
                <div class="row align-items-center">
                    <div class="col text-center">{{ group.title }}</div>
                </div>
            </div>
        </div>
        <div class="about_info_branch_padding">
            <div class="about_info_branch_subtitle text-left" >
                <div><slot></slot></div>
                <div class="about_info_branch_item" v-for="project in group.projects">
                    {{ project }}
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        group: {
            type: Object,
            default: undefined
        }
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<style>

.projects-group {
    padding-top: 20px;
}

.about_info_branch {
    padding: 20px;
    position: relative;
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;

}

.about_info_branch_title_left {
    min-width: 390px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    left: 30px;
    top: -30px;
    padding: 5px 20px;
    position: absolute;
    background-color: #046367;
    color: #ffffff;
    box-shadow: 0 5px 13px rgba(0,0,0,0.3);
    text-align: center;
}

.about_info_branch_padding {
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    min-height: 100%;
}

.about_info_branch_subtitle {
    color: #000000;
    text-align: center;
    font-size: 20px;
    line-height: 1.1;
    font-weight: normal;
    padding: 60px;
    text-align: left;
}

.about_info_branch_item {
    padding-top: 10px;
    padding-bottom: 10px;
}

@media screen and (max-width: 480px) {
    .about_info_branch_subtitle {
        font-size: 16px;
        padding: 20px;
    }

    .projects-group {
        max-width: 440px;
    }

    .about_info_branch_title_left {
        min-width: 190px;
        max-width: 260px;
    }
}

@media screen and (max-width: 360px) {
    .about_info_branch_subtitle {
        font-size: 16px;
        padding: 20px;
    }

    .projects-group {
        max-width: 320px;
    }

    .about_info_branch_title_left {
        min-width: 190px;
        max-width: 260px;
    }
}
</style>