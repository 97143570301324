<template>
<div class='header'>
	<nav class="main-nav">
        <div class="header-panel">
            <div class="menu-top-menu-container">
                <div class='menu-top-logo'><a href='/'><img style='width:55px;' src="/bmstu/logo/nti-bmstu-logo.svg" alt="Центр НТИ"></a></div>
                <ul class="top-menu">
                    <li @click="setItem(item)" v-for="item in items" v-bind:key="item.code" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-46" :class="{ 'current-menu-item': item == current_item, 'current-page-item': item == current_item }">
                        <span>{{ item.title }}</span>
                        <div class="submenu" v-if="item.items" v-show="item.isopen">
                            <div class="submenu-item" v-for="e in item.items" v-bind:key="e.code" @click="setItem(e)">
                                <span>{{ e.title }}</span>
                            </div>
                        </div>
                        
                    </li>
                </ul>
            </div>
            <div class='head_info'>
                <span class='h_info phone'>
                    <img src="/bmstu/img/phone.svg"/>
                    <span>+7 (499) 263 69 86</span>
                </span>
                <span class='h_info email'>
                    <img src="/bmstu/img/email.svg"/>
                    <a href='mailto:nti@bmstu.ru'>nti@bmstu.ru</a>
                </span>
            </div>
        </div>        
    </nav>
</div>
</template>

<script> 
export default {
    mounted: function() {
        this.current_item = this.items[0];
    },
    props: {
        items: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            current_item: undefined,
        }
    },
    methods: {
        setItem(item) {
            if (!item) return;     
            if ( item.items ) {
                for ( let i of this.items ) {
                    if ( i != item ) i.isopen = false;
                }
                item.isopen = ! item.isopen;
                return;
            }
            const code = item.code;
            let index = this.items.findIndex( (e) => e.code == code );
            if (index < 0) {
                for ( let j = 0; j < this.items.length; j++ ) {
                    let i = this.items[j];
                    console.log(i);
                    if (i.items === undefined) continue;
                    const ix = i.items.findIndex( (e) => e.code == code );
                    if ( ix >= 0 ) {
                        index = j;
                        break;
                    }
                }
            }
            if (index < 0) {
                this.current_item = undefined;
            } else {
                this.current_item = this.items[index];
                this.$emit('change', code);
            }            
        }
    },
}
</script>

<style scoped>
.header {
    width: 100%;
    position: fixed;
    margin: 0 auto;
    height: 50px;
    margin-bottom: 30px;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    z-index: 9999;
}

.main-nav {
    width: 100%;
    height: 60px;
    border: 0px;    
    background: rgba(0,0,0,0.6);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.header-panel {
    width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}

.top-menu {
    line-height: 60px;
    float: left;
    margin: 0;
    padding: 0;
}

.top-menu li {
    display: inline-block;
    padding: 10px 14px;
    margin: 0;
    color: white;
}

.menu-top-logo {
    height: 60px;
    float: left;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
}

.head_info {
    float: left;
    text-align: right;
    flex-grow: 1;
    padding: 8px 0px 0px 0px;
    line-height: 60px;
    height: 60px;
    font-size: 14px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 20px;
}

.head_info a {
    color: white;
}

.head_info a:link { text-decoration: none; }

.head_info a:visited { text-decoration: none; }

.head_info a:hover { text-decoration: none; }

.head_info a:active { text-decoration: none; }

.logo img {
    width: 100%;
}

.menu-item:hover {
    color: #0bb0b6;
    cursor: pointer;
}

.menu-item.current-menu-item {
    color: #0bb0b6;    
}
.current-menu-item > span {
    border-bottom: 3px solid #0bb0b6;
}

.h_info {
    display: flex;
    flex-direction: row;
}

.h_info > img {
    width: 25px;
    padding-right: 10px;    
}

@media screen and (max-width: 480px) {
    .header {
        height: auto;
    }
    .header-panel {
        width: 400px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .logo {
        width: 80%;
    }

    .menu-top-menu-container {
        display: none;
    }

    .head_info {
        justify-content: space-between;
    }

    .head_wrapper .text {
        display: none;
    }
}

@media screen and (max-width: 360px) {
    .header {
        height: auto;
    }
    .header-panel {
        width: 320px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .logo {
        width: 80%;
    }

    .menu-top-menu-container {
        display: none;
    }

    .head_info {
        justify-content: left;
    }

    .head_wrapper .text {
        display: none;
    }
}

.submenu {
  position: absolute;
  top: 3.7em;
  background-color: rgba(0,0,0,0.5);
  line-height: normal;
  min-width: 150px;
}

.submenu-item {
    padding: 10px;
    color: white;
}

.submenu-item:hover {
    color: inherit;
}

</style>