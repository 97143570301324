<template> 
<div class="section">
    <div class='title_center' :id="code">
        <h2>{{ title }}</h2>
    </div>
    <slot></slot>
</div>
</template>

<script>
export default {
    props: {
        code: {
            type: String,
        },
        title: {
            type: String,
        },
    },
    data() {
        return {
        }
    },
}
</script>

<style>
.section {
    padding-top: 10px;
    padding-bottom: 10px;
}
.title_center {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}
.title_center > h2 {
    text-transform: uppercase;
}

.section > p {
    padding-bottom: 10px;
}
</style>