<template>
<div class='footer'>
    <div class="foot_wrapper">
        <div class="foot-logo">
            <img src="/bmstu/logo/Logo_White_No-BG.svg" style="width: 150px" />
            <div style="display: flex; flex-direction: column; justify-content: center;">
                <img src="/bmstu/logo/logo-rus/svg/fund-nti-white+color-vert-2.svg" style="width: 150px" />
                <a href="https://bmstu.ru" style="margin-left: 15px;">www.bmstu.ru</a>
            </div>            
        </div>
        <div class="foot-about">
            Центр компетенций НТИ «Цифровое материаловедение: новые материалы и вещества» МГТУ им. Н.Э. Баумана
        </div>
        <div class="foot-contacts">
            <div class="contact-item">
                <div class="contact">
                    <div class="contact-logo">
                        <img src="/img/map-point-wave.svg" />
                    </div>
                    <div class="contact-content">
                        <div class="contact-item-title">Адрес:</div>
                        <div class="contact-item-content">
                            Лефортовская набережная, дом 1
                            <a href="https://yandex.ru/maps/213/moscow/house/lefortovskaya_naberezhnaya_1/Z04YcA9pT0wPQFtvfXt3eXRgZQ==/?ll=37.689698%2C55.768831&z=17">
                                Посмотреть на карте
                            </a>
                        </div>
                    </div>
                </div>
                
                <div class="contact">
                    <div class="contact-logo">
                        <img src="/img/telegram-communication-chat-interaction-network-connection.svg" />
                    </div>
                    <div class="contact-content">
                        <div class="contact-item-title">Telegram-канал ЦК НТИ МГТУ:</div>
                        <div class="contact-item-content">
                            <a href="https://t.me/nti_bmstu">
                                https://t.me/nti_bmstu
                            </a>
                        </div>
                    </div>                    
                </div>                
            </div>
            <!--
            <div class="contact-item">
                <div class="contact-item-title">Телефоны:</div>
                <div class="contact-item-content">
                    <span>+7 (495) 120 30 75</span>
                    <span>+7 (499) 267 00 63</span>
                </div>
                <div class="contact-item-title">Факс:</div>
                <div class="contact-item-content">
                    <span>+7 (495) 120 30 75</span>
                </div>
            </div>
            <div class="contact-item">
                <div class="contact-item-title">Для прессы:</div>
                <div class="contact-item-content">
                    <span>+7 (903) 559 73 72</span>
                    <span>nti@bmstu.ru</span>
                </div>
            </div>
            -->
        </div>
    </div>
    <div class="foot-copyright">
        © {{ new Date().getFullYear() }} Центр НТИ «Цифровое материаловедение: новые материалы и вещества» МГТУ им. Н.Э. Баумана
    </div>
</div>
</template>

<script> 
export default {
    mounted: function() {
        this.current_item = this.items[0];
    },
    props: {
        items: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            current_item: undefined,
        }
    },
    methods: {
        setItem(item) {
            if ( this.current_item != item ) {
                this.current_item = item;
                this.$emit('change', item.code);
            }            
        }
    },
}
</script>

<style scoped>

.footer {    
    width: 100%;
    margin: 0 auto;
    height: 250px;
    margin-top: 30px;
    background-color: #046367;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.foot-copyright {
    padding-bottom: 20px;
    text-align: right;
    width: 1200px;
    color: white;
    font-size: smaller;
}

.foot_wrapper {
    width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 20px;
}

.foot-about {
    text-align: left;
    width: 400px;
    color: white;
}

.foot-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.foot-contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 600px;
    height: 100%;
}

.contact-item {
    display: flex;
    flex-direction: column;
    width: 400px;
    padding-right: 20px;
    font-size: smaller;
}

.contact-item-title {
    font-weight: bold;
    color: #0bb0b6;
    padding-bottom: 5px;
}
.contact-item-content {
    display: flex;
    flex-direction: column;
    color: white;
}

.contact {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-top: 15px;
}

.contact .contact-logo {
    padding: 10px;
    padding-right: 20px;
}

.contact .contact-logo > img {
    max-width: 40px;
    max-height: 40px;

}

.footer a {
    color: white;
    text-decoration-line: underline;
}

@media screen and (max-width: 1350px) {
    .foot_wrapper {
        width: 1050px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (max-width: 1200px) {
    .foot_wrapper {
        width: 850px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (max-width: 950px) {
    .foot_wrapper {
        width: 750px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 25px;
    }
    .about {
        display: none;
    }

    .contacts {
        width: 100%;
        max-width: 100%;
        justify-content: right;
    }
}

@media screen and (max-width: 480px) {
    .foot_wrapper {
        width: 400px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

</style>