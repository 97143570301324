<template> 
<div class="team">
    <div class="col-12 about_team_nva" v-if="leader" @click="choose(leader)">
        <div class="row">        
            <div class="col-auto about_team_nva_img">
                <img :src="leader.photo">
            </div>
            <div class="col about_team_nva_block">
                <div class="about_team_nva_text">
                    <span class="leader-name">{{ leader.name }}</span>
                    <span class="leader-about">{{ leader.about }}</span>
                </div>
                <div class="about_team_nva_quote align-self-end d-none d-lg-block">
                    <slot></slot>
                </div>
            </div>        
        </div>
    </div>
    <div class="main-team">
        <div class="main-team-person" v-for="person in team"  @click="choose(person)">
            <span class="main-team-person-photo" ><img :src="person.photo"/></span>
            <div class="main-team-person-data">
                <span class="main-team-person-name">{{ person.name }}</span>
                <span class="main-team-person-about">{{ person.about }}</span>
            </div>            
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        leader: {
            type: Object,
            default: undefined
        },
        team: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
        }
    },
    methods: {
        choose(person) {
            this.$emit('choose', person);
        }
    }
}
</script>

<style scoped>

.team {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-team {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 100px;
    row-gap: 50px;
    grid-auto-rows: minmax(100px, auto);
}

.main-team-person {
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: lightgrey;
    text-align: center;
    min-height: 320px;
    padding-bottom: 15px;
}

.main-team-person-photo {
    width: 100%;
    flex:1;
}

.main-team-person-photo > img {
    width: 100%;
}

.main-team-person-name {
    font-size: smaller;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
}

.main-team-person-about {
    font-size: smaller;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.main-team-person-data {
    display: flex;
    flex-direction: column;
}

.main-team-person {
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .main-team {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 50px;
        row-gap: 30px;
        grid-auto-rows: minmax(100px, auto);
    }

    .main-team-person {
        display: flex;
        flex-direction: column;
        width: 200px;
        background-color: lightgrey;
        text-align: center;
        min-height: 280px;
    }
}

@media screen and (max-width: 480px) {
    .main-team {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        row-gap: 10px;
        grid-auto-rows: minmax(100px, auto);
    }

    .main-team-person {
        display: flex;
        flex-direction: column;
        width: 150px;
        background-color: lightgrey;
        text-align: center;
        min-height: 150px;
    }
}
</style>